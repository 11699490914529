import { DatePicker, Select, Radio, TimePicker, Button } from "antd"
import { CloseCircleOutlined } from "@ant-design/icons"
import TextArea from "antd/lib/input/TextArea"
import moment from "moment"
import { getTextField, getCheckbox } from "./fields"
import { DocumentUpload } from "../DocumentUpload"
import { PresignedUrlFileIcon } from "../../PresignedUrlFileIcon"
import { SheetUpload } from "../SheetUpload"
import { onChangeCalculateAnotherFieldValue } from "./utils"

const { Option } = Select

export const DEFAULT_INPUT_MAX_LIMIT = 200

export const DEFAULT_INPUT_MIN_LIMIT = 1

export const DEFAULT_NUMBER_MAX_LIMIT = 20

export const DEFAULT_NUMBER_MIN_LIMIT = 1

export const DEFAULT_ADDRESS_MAX_LENGTH = 400

export const DEFAULT_TEXT_AREA_MAX_LIMIT = 200

export const DEFAULT_ALLOWED_FILE_EXTENSIONS = ["application/pdf", "image/png", "image/jpg", "image/jpeg"]

export const DEFAULT_MAX_FILE_SIZE_IN_MB = 10

export const DEFAULT_LABEL_FILE_LINK_STYLE = {
  color: "var(--primary-color)",
  padding: 0,
  marginLeft: "0.5rem",
}

export const getBottomOfField = (field) => {
  let bottom = ""
  bottom = field?.bottom || ""
  const urlText = field?.url_text || ""
  const urlValue = field?.url_value || ""
  if (urlText && urlValue) {
    bottom = <PresignedUrlFileIcon documentId={urlValue} showLoader showAsText text={urlText} />
  }
  return bottom
}

export const getDateField = (fieldDetails) => {
  const { placeholder = "", formObj, disabled = false } = fieldDetails
  const fieldLabel = fieldDetails?.step_name_text || "Date"
  const updatedPlaceholder = placeholder || `Select ${fieldLabel}`
  let disabledDate = ""
  disabledDate = fieldDetails?.disabledDate || ""
  if (fieldDetails?.disable_future_date) {
    disabledDate = (d) => !d || d.isAfter(new Date())
  }
  if (fieldDetails?.disable_past_date) {
    disabledDate = (d) => !d || d.isBefore(moment().subtract(1, "day"))
  }
  return (
    <DatePicker
      placeholder={updatedPlaceholder}
      className="w-100"
      format={(date) => date?.utc(true).format("DD/MM/YYYY")}
      disabledDate={disabledDate}
      disabled={disabled}
      onChange={() => {
        if (fieldDetails?.on_change_calculate_field) {
          onChangeCalculateAnotherFieldValue({
            calculationConfig: fieldDetails?.on_change_calculate_field,
            formObj,
          })
        }
      }}
    />
  )
}

export const getTimeField = (fieldDetails) => {
  const { disabledTime = false, placeholder, format, use12Hours = false, showSecond = false } = fieldDetails
  const fieldLabel = fieldDetails?.step_name_text || "Time"
  const updatedPlaceholder = placeholder || `Select ${fieldLabel}`
  const timeFormat = format || "HH:mm"
  return (
    <TimePicker
      placeholder={updatedPlaceholder}
      className="w-100"
      format={(time) => time.utc(true).format(timeFormat)}
      disabled={disabledTime}
      use12Hours={use12Hours}
      showSecond={showSecond}
    />
  )
}

export const fillValuesOnChange = ({ fillOnChange, formObj, value }) => {
  let valuesToFill = {}
  valuesToFill = fillOnChange[value] || {}
  const fillValues = Object.values(valuesToFill)
  const firstFillValue = fillValues[0] || ""
  const isNestedFill = typeof firstFillValue === "object"
  if (JSON.stringify(valuesToFill) !== "{}") {
    if (isNestedFill) {
      Object.keys(valuesToFill).forEach((dependentKey) => {
        const depCurrentValue = formObj.getFieldValue(dependentKey) || ""
        const depValueMap = valuesToFill[dependentKey] || {}
        if (depCurrentValue && dependentKey && depValueMap) {
          const depValueSet = depValueMap[depCurrentValue] || {}
          if (JSON.stringify(depValueSet) !== "{}") {
            formObj.setFieldsValue(depValueSet)
          }
        }
      })
    } else {
      formObj.setFieldsValue(valuesToFill)
    }
  }
}

export const getSelectField = (fieldDetails) => {
  const { name = "", placeholder = "", options = [], onChange, formObj, fillOnChange } = fieldDetails
  const fieldLabel = fieldDetails?.step_name_text || ""
  const updatedPlaceholder = placeholder || `Select ${fieldLabel}`
  const hideLoader = fieldDetails?.hide_loader || false
  const showLoader = !hideLoader && options.length === 0
  return (
    <Select
      placeholder={updatedPlaceholder}
      onChange={(value) => {
        if (fillOnChange) {
          fillValuesOnChange({ fillOnChange, formObj, value })
        }
        // if (name === "speciality_selection" || name === "brand_selection" || name === "scheme") {
        if (onChange) {
          onChange(name, value)
        }
        // }
      }}
      loading={showLoader}
      disabled={options.length === 0}
      showSearch
      optionLabelProp="label"
      optionFilterProp="label"
      allowClear
    >
      {Array.isArray(options) &&
        options.map((option, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Option key={`${name}_${option?.id}_${index}`} value={option?.id} label={option?.name || ""}>
            {option?.name || ""}
          </Option>
        ))}
    </Select>
  )
}

export const getRadioField = ({
  onChange = () => {},
  options = [],
  defaultValue = "",
  name = "",
  fillOnChange,
  formObj,
}) => (
  <Radio.Group
    name={name}
    defaultValue={defaultValue}
    onChange={(e) => {
      const changedValue = e?.target?.value || ""
      onChange(changedValue)
      if (fillOnChange) {
        fillValuesOnChange({ fillOnChange, formObj, value: changedValue })
      }
    }}
  >
    {options.map((option) => (
      <Radio value={option?.id} key={option?.id}>
        {option?.name || ""}
      </Radio>
    ))}
    <Button
      type="text"
      className="textPrimary"
      onClick={() => {
        formObj.resetFields([name])
      }}
      style={{ color: "var(--primary-color)" }}
      icon={<CloseCircleOutlined />}
    />
  </Radio.Group>
)

export const mandatoryRule = (inputField) => {
  const isRequired = inputField?.is_mandatory || false
  const label = inputField?.step_name_text || ""
  const inputType = inputField?.field_type || ""
  const mandatoryDependsOn = inputField?.mandatory_depends_on || ""
  const multiselect = inputField?.multiselect || false
  let requiredRule = {}
  let actionVerb = ""
  actionVerb = "enter"
  if (inputType === "dropdown" || inputType === "date") {
    actionVerb = "select"
  }
  if (inputType === "file_upload") {
    actionVerb = "upload"
  }
  let message = ""
  message = label ? `Required field, please ${actionVerb} ${label}` : "Required Field"
  requiredRule = {
    required: true,
    message,
  }
  if (inputType === "checkbox") {
    message = `Required field, please accept ${label}`
    if (multiselect) {
      message = `Required field, please select one`
    }
    requiredRule = {
      validator(_, value) {
        if (!multiselect && value !== true) {
          return Promise.reject(new Error(message))
        }
        if (multiselect && !value) {
          return Promise.reject(new Error(message))
        }
        return Promise.resolve()
      },
    }
  }
  if (!isRequired) {
    requiredRule = {}
    if (mandatoryDependsOn.length > 0) {
      const isMultipleDependencies = Array.isArray(mandatoryDependsOn)
      requiredRule = ({ getFieldValue, getFieldsValue }) => ({
        validator(_, value = "") {
          if (isMultipleDependencies) {
            const dependenciesObj = getFieldsValue(mandatoryDependsOn)
            const isAnyDependencyPresent = Object.values(dependenciesObj).some(
              (depVal) => depVal && depVal !== ""
            )
            if (isAnyDependencyPresent && !value) {
              return Promise.reject(new Error(message))
            }
          }
          if (!isMultipleDependencies) {
            const dependencyValue = getFieldValue(mandatoryDependsOn)
            if (dependencyValue && !value) {
              return Promise.reject(new Error(message))
            }
          }
          return Promise.resolve()
        },
      })
    }
  }
  return requiredRule
}

export const onlyAlphabetsRule = ({ maxLength = "", minLength = "" }) => [
  () => ({
    validator(record, value = "") {
      const fieldName = record?.field?.toLowerCase() || ""
      const textAreaFields = ["description", "notes", "comment"]
      const isTextArea = textAreaFields.some((key) => fieldName.includes(key))
      const maxAllowedLength = isTextArea ? 100 : maxLength || DEFAULT_INPUT_MAX_LIMIT
      const valueLength = value?.length || 0
      const isLengthValid = valueLength <= maxAllowedLength
      if (!isLengthValid) {
        return Promise.reject(new Error(`Maximum allowed characters ${maxAllowedLength}`))
      }
      return Promise.resolve()
    },
  }),
  {
    pattern: `^([A-Za-z ]+)$`,
    message: "Only Alphabets are allowed",
  },
  {
    min: minLength || DEFAULT_INPUT_MIN_LIMIT,
    message: `Minimum ${minLength || DEFAULT_INPUT_MIN_LIMIT} characters needed`,
  },
]

export const onlyNumbersRule = ({ message = "", maxLength = "", minLength = "" }) => [
  () => ({
    validator(_, value = "") {
      if (!value) {
        return Promise.resolve()
      }
      const pattern = `^([0-9]{${minLength || DEFAULT_NUMBER_MIN_LIMIT},${
        maxLength || DEFAULT_NUMBER_MAX_LIMIT
      }})$`
      const regex = new RegExp(pattern)

      if (value.length < (minLength || DEFAULT_NUMBER_MIN_LIMIT)) {
        const minMessage = `Only number with minimum ${minLength || DEFAULT_NUMBER_MIN_LIMIT} digits allowed`
        return Promise.reject(new Error(message || minMessage))
      }

      if (value.length > (maxLength || DEFAULT_NUMBER_MAX_LIMIT)) {
        if (maxLength) {
          return Promise.resolve()
        }
        const maxMessage = `Maximum ${maxLength || DEFAULT_NUMBER_MAX_LIMIT} digits allowed`
        return Promise.reject(new Error(message || maxMessage))
      }

      if (regex.test(value)) {
        return Promise.resolve()
      }

      const combinedMessage = `Only number with minimum ${
        minLength || DEFAULT_NUMBER_MIN_LIMIT
      } and maximum ${maxLength || DEFAULT_NUMBER_MAX_LIMIT} digits allowed`
      return Promise.reject(new Error(message || combinedMessage))
    },
  }),
]

export const alphaNumbericRule = ({ maxLength = "", minLength = "" }) => [
  () => ({
    validator(_, value = "") {
      const allowedCharsPattern = /^[A-Za-z0-9 ]*$/
      const maxLen = maxLength || DEFAULT_INPUT_MAX_LIMIT
      const minLen = minLength || DEFAULT_INPUT_MIN_LIMIT
      const isPatternInValid = !allowedCharsPattern.test(value)
      if (!value) {
        return Promise.resolve()
      }
      if (isPatternInValid) {
        return Promise.reject(new Error("Allowed Characters: [A-Za-z0-9 ]"))
      }
      if (value.length > maxLen) {
        return Promise.reject(new Error(`Maximum characters allowed ${maxLen}`))
      }
      if (value.length < minLen) {
        return Promise.reject(new Error(`Minimum characters required ${minLen}`))
      }
      return Promise.resolve()
    },
  }),
]

export const invoiceNumberRule = ({ maxLength = "" }) => [
  () => ({
    validator(_, value = "") {
      const allowedCharsPattern = /^[A-Za-z0-9/() .,\\-]*$/
      const maxLen = maxLength || DEFAULT_INPUT_MAX_LIMIT
      const isPatternInValid = !allowedCharsPattern.test(value)
      if (isPatternInValid) {
        return Promise.reject(new Error("Allowed Characters: [A-Z a-z 0-9 . , - / ()]"))
      }
      if (value.length > maxLen) {
        return Promise.reject(new Error(`Maximum characters allowed ${maxLen}`))
      }
      return Promise.resolve()
    },
  }),
]

export const quantityRule = ({ maxLength = "", minLength = "" }) => {
  let quantityRules = []
  quantityRules = [
    () => ({
      validator(_, value = "") {
        const allowedCharsPattern = /^[0-9]*$/
        const minValue = 1
        const isMinValuePassed = value >= minValue
        const isPatternValid = !allowedCharsPattern.test(value)
        if (value && isPatternValid) {
          return Promise.reject(new Error("Only Numbers are allowed"))
        }
        if (value !== "" && !isMinValuePassed) {
          return Promise.reject(new Error(`Minimum Quantity allowed : ${minValue}`))
        }
        return Promise.resolve()
      },
    }),
    {
      min: minLength || DEFAULT_INPUT_MIN_LIMIT,
      message: `Minimum ${minLength || DEFAULT_INPUT_MIN_LIMIT} characters needed`,
    },
  ]
  if (!maxLength) {
    quantityRules.push({
      max: DEFAULT_INPUT_MAX_LIMIT,
      message: `Maximum allowed characters ${DEFAULT_INPUT_MAX_LIMIT}`,
    })
  }
  return quantityRules
}

export const schemeRule = ({ maxLength = "", minLength = "" }) => [
  () => ({
    validator(_, value = "") {
      const allowedCharsPattern = /^[A-Za-z0-9()+ ]*$/
      if (value && !allowedCharsPattern.test(value)) {
        return Promise.reject(new Error("Allowed Characters: Alphabets, Number and [(,),+]"))
      }
      return Promise.resolve()
    },
  }),
  {
    min: minLength || DEFAULT_INPUT_MIN_LIMIT,
    message: `Minimum ${minLength || DEFAULT_INPUT_MIN_LIMIT} characters needed`,
  },
  {
    max: maxLength || DEFAULT_INPUT_MAX_LIMIT,
    message: `Maximum allowed characters ${maxLength || DEFAULT_INPUT_MAX_LIMIT}`,
  },
]

export const addressRule = ({ maxLength = "", minLength = "" }) => {
  let addrRules = []
  addrRules = [
    () => ({
      validator(_, value = "") {
        const allowedCharsPattern = /^[A-Za-z0-9\-,.|+/()#&:;'\\ [\] ]*$/
        if (value && !allowedCharsPattern.test(value)) {
          return Promise.reject(new Error("Allowed Characters: Alphabets, Number and [-,.|+/()#&:;'\\ []]"))
        }
        return Promise.resolve()
      },
    }),
    {
      min: minLength || DEFAULT_INPUT_MIN_LIMIT,
      message: `Minimum ${minLength || DEFAULT_INPUT_MIN_LIMIT} characters needed`,
    },
  ]
  if (!maxLength) {
    addrRules.push({
      max: maxLength || DEFAULT_ADDRESS_MAX_LENGTH,
      message: `Maximum allowed characters ${maxLength || DEFAULT_ADDRESS_MAX_LENGTH}`,
    })
  }
  return addrRules
}

export const emailRule = ({ message = "" }) => ({
  type: "email",
  message: message || "Invalid Email ID",
})

export const getDependencies = (field) => {
  const name = field?.step_name || ""
  let dependencies = ""
  dependencies = []
  if (name === "rx_start_date") {
    dependencies = ["rx_end_date"]
  }
  if (name === "rx_end_date") {
    dependencies = ["rx_start_date"]
  }
  return dependencies
}

export const isDateValidationInError = ({ condition = "", value = "", otherFieldValue = "" }) => {
  if (condition && value && otherFieldValue) {
    const isAfter = value > otherFieldValue
    const isBefore = value < otherFieldValue
    const valueDateString = value?.format("DD-MM-YYYY") || ""
    const otherValueDateString = otherFieldValue?.format("DD-MM-YYYY") || ""
    const isSame = valueDateString === otherValueDateString
    const afterFailed = isBefore || isSame
    const beforeFailed = isAfter || isSame
    return (condition === "after" && afterFailed) || (condition === "before" && beforeFailed)
  }
  return false
}

export const dateRule = (field) => [
  ({ getFieldValue }) => ({
    validator(_, value = "") {
      const name = field?.step_name || ""
      const { validations = [] } = field
      if (value && validations?.length > 0) {
        const firstFailure = validations?.find((checkConfig) => {
          const { fieldKey = "", condition = "" } = checkConfig
          const otherFieldValue = getFieldValue(fieldKey)
          const isError = isDateValidationInError({ condition, value, otherFieldValue })
          return isError
        })
        if (firstFailure) {
          const { errorMessage = "" } = firstFailure
          return Promise.reject(new Error(errorMessage))
        }
      }
      if (value && name === "rx_start_date") {
        const rxEndDate = getFieldValue("rx_end_date")
        if (rxEndDate && value > rxEndDate) {
          return Promise.reject(new Error("Rx Start Date can not be after Rx End Date"))
        }
      }
      if (value && name === "rx_end_date") {
        const rxStartDate = getFieldValue("rx_start_date")
        if (rxStartDate && value < rxStartDate) {
          return Promise.reject(new Error("Rx End Date can not be before Rx Start Date"))
        }
      }
      return Promise.resolve()
    },
  }),
]

export const pincodeRule = () => [
  () => ({
    validator(_, value = "") {
      const allowedCharsPattern = /^[0-9]{6,6}$/
      const isPatternValid = allowedCharsPattern.test(value)
      if (value && !isPatternValid) {
        return Promise.reject(new Error("Invalid Pincode"))
      }
      return Promise.resolve()
    },
  }),
]

export const merckUniqueIdRule = () => [
  () => ({
    validator(_, value = "") {
      const allowedCharsPattern = /^[0-9]{0,10}$/
      const isPatternValid = allowedCharsPattern.test(value)
      if (value && !isPatternValid) {
        return Promise.reject(new Error("Invalid Merck ID"))
      }
      return Promise.resolve()
    },
  }),
]

export const mobileNumberRule = (field) => [
  () => ({
    validator(_, value = "") {
      const allowedCharsPattern = /^[0-9]{10,10}$/
      const isPatternValid = allowedCharsPattern.test(value)
      if (value && !isPatternValid) {
        return Promise.reject(new Error(`Invalid ${field?.step_name_text || "Mobile Number"}`))
      }
      return Promise.resolve()
    },
  }),
]

export const typeRuleMapping = (field) => ({
  text: onlyAlphabetsRule(field),
  address: addressRule(field),
  quantity: quantityRule(field),
  scheme: schemeRule(field),
  mobileNumber: mobileNumberRule(field),
  pincode: pincodeRule(field),
  email: emailRule(field),
  number: onlyNumbersRule(field),
  date: dateRule(field),
  merckUniqueId: merckUniqueIdRule(field),
  alphanumeric: alphaNumbericRule(field),
  invoiceNumber: invoiceNumberRule(field),
  textarea: alphaNumbericRule(field),
})

export const getCalculatedFieldType = (field) => {
  const name = field?.step_name || ""
  const type = field?.field_type || ""
  const label = field?.step_name_text || ""
  const numberOnlyFields = ["cycles_prescribed"]
  let calculatedType = ""
  calculatedType = type
  if (
    name.includes("mobile") ||
    name.includes("phone") ||
    label.includes("Phone") ||
    name.includes("contact_number")
  ) {
    calculatedType = "mobileNumber"
  } else if (name.includes("email") || label.includes("email")) {
    calculatedType = "email"
  } else if (name.includes("pincode") || name.includes("pin_code")) {
    calculatedType = "pincode"
  } else if (name.includes("address") && type !== "dropdown") {
    calculatedType = "address"
  } else if (numberOnlyFields.includes(name)) {
    calculatedType = "number"
  } else if (name.includes("other_scheme")) {
    calculatedType = "scheme"
  } else if (name.includes("quantity")) {
    calculatedType = "quantity"
  } else if (name === "merck_unique_id") {
    calculatedType = "merckUniqueId"
  } else if (name === "number_of_cycles") {
    calculatedType = "alphanumeric"
  } else if (name === "number_of_vials") {
    calculatedType = "alphanumeric"
  } else if (name === "vendor_name" || label.includes("LRN")) {
    calculatedType = "alphanumeric"
  }
  if (type === "file_upload" || type === "radio" || type === "dropdown") {
    calculatedType = type
  }
  return calculatedType
}

export const getCustomSectionConditionKeys = (customSubSectionKey) => customSubSectionKey !== "steps"

export const findCustomSubSection = ({ customSubSection = {}, allSelectedDropdownValues = {} }) => {
  const customKeys = Object.keys(customSubSection)?.filter(getCustomSectionConditionKeys) || []
  const allConditionsSatisfied = customKeys?.every(
    (conditionKey) =>
      conditionKey &&
      conditionKey in allSelectedDropdownValues &&
      allSelectedDropdownValues[conditionKey] &&
      allSelectedDropdownValues[conditionKey] === customSubSection[conditionKey]
  )
  return allConditionsSatisfied
}

export const getRules = (field) => {
  let rules = ""
  const { overrideDefaultRules = false } = field
  const keysWithMutuallyExclusiveValues = field?.keys_with_mutually_exclusive_values || []
  const valueBasedMandatoryConditions = field?.mandatory_if || []
  const otherValueBasedRegexCheck = field?.other_value_based_regex_check || {}
  const customRules = field?.rules || []
  const calculatedType = getCalculatedFieldType(field)
  const type = calculatedType || ""
  const isRequired = field?.is_mandatory || false
  const isDependendedMandatory = field?.mandatory_depends_on?.length > 0
  const noCharRestriction = field?.no_char_restriction || false
  const fieldTypeRules = typeRuleMapping(field)
  let fieldTypeRuleMapper = []
  fieldTypeRuleMapper = fieldTypeRules?.[type] || []
  if (noCharRestriction) {
    fieldTypeRuleMapper = []
  }
  const mandatory = mandatoryRule(field)
  rules = isRequired || isDependendedMandatory ? [mandatory] : []
  if (field.regex) {
    rules.push({
      pattern: field.regex,
      message: field?.regexMessage || "Invalid Value",
    })
  }
  if (field.maxLength) {
    rules.push({
      validator: (_, value) => {
        const maxLengthRegex = new RegExp(`^.{0,${field.maxLength}}$`)
        if (maxLengthRegex.test(value) || !value) {
          return Promise.resolve()
        }
        return Promise.reject(new Error(`Maximum allowed characters ${field.maxLength}`))
      },
    })
  }

  if (field.minValue) {
    rules.push({
      validator: (_, value) => {
        if (Number(value) >= field.minValue || !value) {
          return Promise.resolve()
        }
        return Promise.reject(new Error(`Value must be greater than or equal to ${field.minValue}`))
      },
    })
  }

  if (fieldTypeRuleMapper) {
    if (Array.isArray(fieldTypeRuleMapper)) {
      rules = rules.concat(fieldTypeRuleMapper)
    } else {
      rules[rules.length] = fieldTypeRuleMapper
    }
  }
  if (keysWithMutuallyExclusiveValues.length > 0) {
    const mutuallyExclusiveRule = ({ getFieldsValue }) => ({
      validator(_, value = "") {
        const exclusivelyValues = getFieldsValue(keysWithMutuallyExclusiveValues)
        const exclusivelyValuesList = keysWithMutuallyExclusiveValues.join(", ")
        const isExclusivelyValuesPresent = Object.values(exclusivelyValues).some(
          (depVal) => depVal && depVal !== ""
        )
        if (isExclusivelyValuesPresent && value) {
          return Promise.reject(
            new Error(`Value not allowed when any of ${exclusivelyValuesList} is present`)
          )
        }
        if (!isExclusivelyValuesPresent && !value) {
          return Promise.reject(new Error(`Any of ${exclusivelyValuesList} is mandatory`))
        }
        return Promise.resolve()
      },
    })
    rules[rules.length] = mutuallyExclusiveRule
  }
  if (otherValueBasedRegexCheck.regex) {
    const valueBasedRegexRule = ({ getFieldValue }) => ({
      validator(_, value) {
        if (value) {
          const { key = "", conditionValue = "", regex = "", regexMessage = "" } = otherValueBasedRegexCheck
          const conditionKeyValue = getFieldValue(key)
          if (conditionKeyValue === conditionValue) {
            const regexPattern = new RegExp(regex)
            if (regexPattern.test(value)) {
              return Promise.resolve()
            }
            return Promise.reject(new Error(regexMessage))
          }
        }
        return Promise.resolve()
      },
    })
    rules[rules.length] = valueBasedRegexRule
  }
  if (valueBasedMandatoryConditions.length > 0) {
    const valueBasedMandatoryRule = ({ getFieldValue }) => ({
      validator(_, value = "") {
        if (!value) {
          const someHasValue = valueBasedMandatoryConditions?.some((conditionSet) => {
            const { key = "", condition = "" } = conditionSet
            const conditionVal = conditionSet?.condition_val || ""
            const val = getFieldValue(key)
            if (condition && val && conditionVal) {
              if (condition === "equal") {
                if (val === conditionVal) {
                  return true
                }
              }
            }
            return false
          })
          if (someHasValue) {
            return Promise.reject(new Error("Required field"))
          }
        }
        return Promise.resolve()
      },
    })
    rules[rules.length] = valueBasedMandatoryRule
  }
  if (customRules.length > 0) {
    if (overrideDefaultRules) {
      rules = customRules
    } else {
      rules = rules.concat(customRules)
    }
  }
  return rules
}

export const getUploadField = (fileUploadOptions) => {
  const { name, uploadFileParams, formObj, dependencies = [] } = fileUploadOptions
  const documentType = fileUploadOptions.document_type || ""
  const {
    patientId = "",
    programId = "",
    isEdit = false,
    ocrFileType = "",
    appId = "",
    appUid = "",
  } = uploadFileParams
  return (
    <DocumentUpload
      name={name}
      parentFormObj={formObj}
      patientId={patientId}
      programId={programId}
      isEdit={isEdit}
      dependencies={dependencies}
      ocrFileType={ocrFileType ? documentType : ""}
      appId={appId}
      appUid={appUid}
      multipleMode={fileUploadOptions?.enable_multiple_mode || false}
      maxFileCount={fileUploadOptions?.max_file_count || 1}
      allowedFileExtensions={fileUploadOptions?.allowed_file_extensions || DEFAULT_ALLOWED_FILE_EXTENSIONS}
      maxFileSizeInMB={fileUploadOptions?.max_file_size_in_mb || DEFAULT_MAX_FILE_SIZE_IN_MB}
    />
  )
}

export const getSheetUploadField = (fileUploadOptions) => {
  const { name, formObj, dependencies = [] } = fileUploadOptions
  return <SheetUpload name={name} parentFormObj={formObj} dependencies={dependencies} />
}

export const getTextArea = (field) => {
  let placeholder = field?.step_name_text || ""
  const maxLen = field?.maxLength || field?.max_length || DEFAULT_TEXT_AREA_MAX_LIMIT
  const maxRows = field?.maxRows || field?.max_rows || 4
  placeholder = `Enter ${placeholder}`
  if (field?.placeholder) {
    placeholder = field?.placeholder
  }
  return <TextArea rows={maxRows} maxLength={maxLen} placeholder={placeholder} />
}

export const getStateBtn = ({ field, stateVarActions, stateVars }) => {
  const key = field?.state_var || ""
  const action = field?.state_action || ""
  const onClickAction = () => {
    stateVarActions({ key, action })
  }
  const stateVar = stateVars[key] || {}
  const { val = "", maxval = "" } = stateVar
  let isVisible = ""
  isVisible = false
  if (val !== "" && maxval !== "") {
    isVisible = val < maxval
  }
  return (
    isVisible && (
      <div className="w-100 text-center">
        <Button type="secondary" className="dls-secondary-btn w-auto" onClick={onClickAction}>
          {field?.label || ""}
        </Button>
      </div>
    )
  )
}

export const getField = ({
  field = {},
  options = [],
  chooseLabelSelect = false,
  onDropdownValueChange,
  uploadFileParams,
  formObj,
  formType = "",
}) => {
  let inputField = ""
  const calculatedType = getCalculatedFieldType(field)
  const type = calculatedType || ""
  const name = field?.step_name || ""
  const { onChange = () => {}, defaultValue = "", placeholder = "", dependencies = [] } = field
  const fillOnChange = field?.on_change_fill_values || {}
  const fieldDetailsWithFormObj = { ...field, formObj }
  inputField = getTextField({ ...field, formType, formObj })
  if (type === "dropdown") {
    inputField = getSelectField({
      name,
      options,
      chooseLabelSelect,
      onChange: onDropdownValueChange,
      placeholder,
      step_name_text: field?.step_name_text || "",
      formObj,
      fillOnChange,
    })
  }
  if (type === "date") {
    inputField = getDateField(fieldDetailsWithFormObj)
  }
  if (type === "time") {
    inputField = getTimeField(field)
  }
  if (type === "radio" || type === "radiobutton") {
    inputField = getRadioField({
      name,
      onChange,
      options,
      chooseLabelSelect,
      defaultValue,
      fillOnChange,
      formObj,
    })
  }
  if (type === "file_upload" || type === "ocr_file") {
    inputField = getUploadField({ ...field, name, uploadFileParams, formObj, dependencies })
  }
  if (type === "checkbox") {
    inputField = getCheckbox({ field, formType, options })
  }
  if (type === "textarea") {
    inputField = getTextArea(field)
  }
  if (type === "sheet_file_upload") {
    inputField = getSheetUploadField({ name, uploadFileParams, formObj, dependencies })
  }
  return inputField
}
